import React, { useState, useEffect } from "react"
import css from "@styled-system/css"
import { graphql, Link } from "gatsby"

import { Box, Flex, H1, H2, H6, Container, SEO, Text } from "components"

import TextBlock from "./components/textBlock"
import Gallery from "./components/gallery"
import Image from "./components/image"
import CallToAction from "./components/callToAction"
import Video from "./components/video"

function PostPage({ data }) {
  const [previousPost, setPreviousPost] = useState(undefined)
  const [nextPost, setNextPost] = useState(undefined)

  useEffect(() => {
    if (data.posts && data.posts.nodes) {
      data.posts.nodes.forEach((post, index) => {
        if (post.url === data.post.url) {
          if (data.posts.nodes[index - 1]) {
            setNextPost(data.posts.nodes[index - 1].url)
          }
          if (data.posts.nodes[index + 1]) {
            setPreviousPost(data.posts.nodes[index + 1].url)
          }
        }
      })
    }
  }, [])

  return (
    <>
      <SEO
        title={data.post.title + " - Blog - Falk"}
        description={
          "Read the blog article entitled " +
          data.post.title +
          " on the Falkbuilt Technologies blog."
        }
        image={data.post.image && data.post.image.url}
      />
      <Box pt={[150, 170]} pb={[5, 6]} px={[0, null, 4, 6]} bg="muted">
        <Box mx="auto" maxWidth={1600}>
          <Box mt={[0, 5]} bg="background">
            <Container style={{ padding: "128px 128px 0px 128px" }}>
              <H1 children={data.post.title} mb={3} />
              {data.post.subtitle && (
                <Text
                  children={data.post.subtitle}
                  mt={[3, 4]}
                  fontFamily="body"
                  fontSize={[4, 5]}
                />
              )}
              <H2
                children={data.post.blogHeading2}
                my="40px"
                fontFamily="body"
                fontSize={[5, 6]}
                fontWeight="bolder"
              />        
            </Container>
            {data.post.postContent.map((content, index) => {
              if (content.__typename === "DatoCmsTextBlock") {
                return <TextBlock content={content} />
              }
              if (content.__typename === "DatoCmsImage") {
                return <Image content={content} />
              }
              if (content.__typename === "DatoCmsGallery") {
                return <Gallery content={content} hideAlt />
              }
              if (content.__typename === "DatoCmsCallToAction") {
                return <CallToAction content={content} />
              }
              if (content.__typename === "DatoCmsVideo") {
                return <Video content={content} />
              }
            })}
            <Box height={1} />
          </Box>
        </Box>
        <Box mt={[5, 6]}>
          <Flex justifyContent="center" alignItems="center">
            {previousPost && (
              <H6>
                <Link
                  children={`Previous post`}
                  to={"/blog/" + previousPost + "/"}
                  css={css({ textDecoration: "none" })}
                />
              </H6>
            )}
            {previousPost && nextPost && (
              <Box
                width="1px"
                height={48}
                bg="primary"
                mx={4}
                css={css({ transform: "translateY(-2px) rotate(45deg)" })}
              />
            )}
            {nextPost && (
              <H6>
                <Link
                  children={`Next post`}
                  to={"/blog/" + nextPost + "/"}
                  css={css({ textDecoration: "none" })}
                />
              </H6>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export const query = graphql`
  query PostQuery($handle: String!) {
    posts: allDatoCmsBlogPost(sort: { order: DESC, fields: postedDate }) {
      nodes {
        url
      }
    }
    post: datoCmsBlogPost(url: { eq: $handle }) {
      url
      image {
        url
      }
      title
      subtitle
      blogHeading2
      postContent {
        __typename
        ... on DatoCmsTextBlock {
          id
          text
        }
        ... on DatoCmsGallery {
          id
          images {
            alt
            url
          }
        }
        __typename
        ... on DatoCmsImage {
          id
          title
          image {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsCallToAction {
          id
          title
          buttonText
          buttonLink
        }
        ... on DatoCmsVideo {
          id
          youtubeLink
        }
      }
    }
  }
`

export default PostPage
